/* eslint-disable max-len */
import React from "react";

const RefreshIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1766_33815)">
      <g clipPath="url(#clip1_1766_33815)">
        <path
          d="M0.745605 4.27295V10.2729H6.74561"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7456 20.2729V14.2729H16.7456"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2356 9.27296C19.7284 7.83975 18.8665 6.55837 17.7301 5.54838C16.5938 4.5384 15.2201 3.83273 13.7373 3.49722C12.2545 3.16171 10.7109 3.2073 9.25041 3.62974C7.78998 4.05217 6.46035 4.83768 5.38561 5.91296L0.745605 10.273M22.7456 14.273L18.1056 18.633C17.0309 19.7083 15.7012 20.4938 14.2408 20.9162C12.7804 21.3386 11.2367 21.3842 9.75389 21.0487C8.27107 20.7132 6.89741 20.0075 5.76107 18.9975C4.62474 17.9876 3.76277 16.7062 3.25561 15.273"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1766_33815">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_1766_33815">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(-0.254395 0.272949)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RefreshIcon;
