import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import classes from "./Feedback.module.scss";

const Feedback: React.FC = (): JSX.Element => {
  const feedback = [
    {
      name: "Mission Realty",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when",
      dateTime: "11 Aug 2022, 11:45 PM",
    },
    {
      name: "Baltimore Markets",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when",
      dateTime: "11 Aug 2022, 11:45 PM",
    },
    {
      name: "Adaptabiz",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when",
      dateTime: "11 Aug 2022, 11:45 PM",
    },
    {
      name: "Multi-Systems Merchant Services",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when",
      dateTime: "11 Aug 2022, 11:45 PM",
    },
    {
      name: "Mission Realty",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when",
      dateTime: "11 Aug 2022, 11:45 PM",
    },
    {
      name: "Baltimore Markets",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when",
      dateTime: "11 Aug 2022, 11:45 PM",
    },
    {
      name: "Adaptabiz",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when",
      dateTime: "11 Aug 2022, 11:45 PM",
    },
    {
      name: "Multi-Systems Merchant Services",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when",
      dateTime: "11 Aug 2022, 11:45 PM",
    },
  ];
  return (
    <div className={`${classes.feedback_container}`}>
      <div className={classes.feedback_card_title}>
        <span className="text-mediumtext 2xl:text-subtitle2 text-gray-900">
          Feedback
        </span>
        <span className="text-primary-main text-body1 cursor-pointer">
          View All
        </span>
      </div>
      <PerfectScrollbar className={`${classes.feedback_list}`}>
        {feedback.map((item, i) => (
          <div className={classes.feedback_item} key={i}>
            <div className={classes.feedback_details}>
              <div className={classes.feedback_name_detail}>
                <span className={classes.feedback_name}>{item.name}</span>
                <span className={classes.feedback_user_role}>Fleet Owner</span>
              </div>
              <span className={classes.feedback_datetimr}>{item.dateTime}</span>
            </div>
            <div className={classes.feedback_desc}>{item.feedback}</div>
          </div>
        ))}
      </PerfectScrollbar>
    </div>
  );
};

export default Feedback;
