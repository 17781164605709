import dynamic from "next/dynamic";
import MenuDotIcon from "../../../Icons/MenuDotIcon";
import classes from "./EnergyUsage.module.scss";
const EnergyUsageGraph = dynamic(() => import("./EnergyUsageGraph"), {
  ssr: false,
});

const EnergyUsage = () => (
  <div className={classes.energyusage_container}>
    <div className={classes.energyusage_menu}>
      <span className="border-1 rounded-sm cursor-pointer">
        <MenuDotIcon />
      </span>
    </div>
    <div className={classes.energyusage_graph_detail}>
      <div className={classes.energyusage_graph}>
        <EnergyUsageGraph />
      </div>
      <div className={classes.energyusage_legends}>
        <div className={classes.energyusage_legend_item}>
          <div className="h-3 w-3 bg-[#7BACF5]" /> Low
        </div>
        <div className={classes.energyusage_legend_item}>
          <div className="h-3 w-3 bg-[#53C8F4]" /> Average
        </div>
        <div className={classes.energyusage_legend_item}>
          <div className="h-3 w-3 bg-[#09DFAD]" /> High{" "}
        </div>
        <div className={classes.energyusage_legend_item}>
          <div className="h-3 w-3 bg-[#50C878]" /> Too High
        </div>
      </div>
    </div>
    <div className={classes.energyusage_data}>
      <div className={classes.energyusage_today}>
        <span className="text-body1">Today (26 Aug 2022 )</span>
        <span className="font-semibold text-subtitle1">
          265 <span className="text-smalltext">MWh</span>
        </span>
      </div>
      <div className={classes.energyusage_dailyaverage}>
        <span className="text-body1">Daily Average</span>
        <span className="font-semibold text-subtitle1">
          44.8 <span className="text-smalltext">MWh</span>
        </span>
      </div>
    </div>
  </div>
);

export default EnergyUsage;
