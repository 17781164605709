import classes from "./FleetAprrovedRequest.module.scss";
const FleetAprrovedRequest = () => (
  <div className={classes.fleetaprrovedrequest_container}>
    <span className={classes.fleetaprrovedrequest_title}>
      Fleet request vs Approved Request
    </span>
    <div className={classes.fleetaprrovedrequest_details}>
      <div className={classes.fleetaprrovedrequest_graph_separate}>
        <span className={classes.fleetaprrovedrequest_total}>
          <div className={classes.fleetaprrovedrequest_total_bubble}>
            <span className={classes.fleetaprrovedrequest_text}>15</span>
            <span className="text-body1">Total Request</span>
          </div>
        </span>
        <span className={classes.fleetaprrovedrequest_approve}>
          <div className={classes.fleetaprrovedrequest_approve_bubble}>
            <span className={classes.fleetaprrovedrequest_text}>8</span>
            <span className="text-body1">Approve Request</span>
          </div>
        </span>
      </div>
    </div>
    <span className={classes.fleetaprrovedrequest_desc}>
      It is a long established fact that a reader will be distracted by the
      readable content of a page when looking at its layout.{" "}
    </span>
  </div>
);

export default FleetAprrovedRequest;
