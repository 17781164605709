import dynamic from "next/dynamic";
import MenuDotIcon from "../../../Icons/MenuDotIcon";
import classes from "./RevenueOfSubscriptions.module.scss";
const RevenueOfSubscriptionsGraph = dynamic(
  () => import("./RevenueOfSubscriptionsGraph"),
  {
    ssr: false,
  }
);

const RevenueOfSubscriptions = () => (
  <div className={classes.revenueofsubscriptions_container}>
    <div className={classes.revenueofsubscriptions_data}>
      <div className={classes.revenueofsubscriptions_revenue}>
        <span className="text-primary-main font-semibold text-subtitle1">
          $ 2,53,687
        </span>
        <span className={classes.revenueofsubscriptions_badge}>
          <span className="font-normal text-primary-main">+10%</span> Since Last
          Month
        </span>
      </div>

      <div className={classes.revenueofsubscriptions_menu}>
        <span className="border-1 rounded-sm cursor-pointer">
          <MenuDotIcon />
        </span>
      </div>
    </div>
    <div className={classes.revenueofsubscriptions_graph}>
      <RevenueOfSubscriptionsGraph />
    </div>
  </div>
);

export default RevenueOfSubscriptions;
