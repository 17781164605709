/* eslint-disable max-len */
import React from "react";

const SessionIcon: React.FC = () => (
  <svg
    width="48"
    height="47"
    viewBox="0 0 48 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.5927 41.6251C39.8374 41.6251 42.4677 38.9948 42.4677 35.7501C42.4677 32.5054 39.8374 29.8751 36.5927 29.8751C33.3481 29.8751 30.7177 32.5054 30.7177 35.7501C30.7177 38.9948 33.3481 41.6251 36.5927 41.6251Z"
      stroke="#9B9B9B"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0927 18.1251C16.3374 18.1251 18.9677 15.4948 18.9677 12.2501C18.9677 9.00542 16.3374 6.37509 13.0927 6.37509C9.84807 6.37509 7.21774 9.00542 7.21774 12.2501C7.21774 15.4948 9.84807 18.1251 13.0927 18.1251Z"
      stroke="#9B9B9B"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8011 12.2501H32.6761C33.7149 12.2501 34.7111 12.6627 35.4456 13.3973C36.1801 14.1318 36.5928 15.128 36.5928 16.1668V29.8751"
      stroke="#9B9B9B"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0927 18.1251V41.6251"
      stroke="#9B9B9B"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SessionIcon;
