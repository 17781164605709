import { Autocomplete, TextField } from "@mui/material";
import React from "react";

interface IOnChangeRes {
  target: {
    value: number[] | number | string | string[] | undefined;
  };
}

export interface IValue {
  label: string;
  value: number | string;
}

export interface DropdownPropsTypes {
  id: string;
  name?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  data: { value: string | number; label: string }[];
  multiple?: boolean;
  value?: IValue | IValue[] | undefined;
  onChange?: (e: IOnChangeRes) => void;
  getOptionDisabled?: (option: IValue | IValue[]) => boolean;
  setValue?:
    | React.Dispatch<React.SetStateAction<IValue>>
    | React.Dispatch<React.SetStateAction<IValue[]>>
    | React.Dispatch<React.SetStateAction<undefined>>;
  key?: any;
  disableClearable?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
}

const Dropdown: React.FC<DropdownPropsTypes> = (props): JSX.Element => (
  <Autocomplete
    disablePortal
    // key={props.key}
    multiple={props.multiple}
    options={props.data}
    value={props.value}
    className={props.className}
    disabled={props.disabled}
    disableClearable={props.disableClearable}
    renderInput={(params) => <TextField {...params} label={props.label} />}
    onChange={(event: any, newValue: any) => {
      if (props.onChange) {
        if (newValue?.length >= 0) {
          const arr = [];
          for (let i = 0; i < newValue.length; i++) {
            arr.push(newValue[i].value);
          }
          props.onChange({ target: { value: arr } });
        } else {
          props.onChange({ target: { value: newValue?.value } });
        }
        if (props.setValue) {
          props.setValue(newValue);
        }
      }
      if (props.setValue) {
        props.setValue(newValue);
      }
    }}
    getOptionDisabled={(option) => {
      if (props.getOptionDisabled) {
        return props.getOptionDisabled(option);
      }
      return false;
    }}
    onKeyDown={(e) => {
      if (props.onKeyDown) {
        props.onKeyDown(e);
      }
    }}
  />
);

Dropdown.defaultProps = {
  className: undefined,
  label: undefined,
  name: undefined,
  placeholder: undefined,
  disabled: undefined,
  multiple: undefined,
  value: undefined,
  onChange: undefined,
  setValue: undefined,
  getOptionDisabled: undefined,
  key: undefined,
  disableClearable: true,
  onKeyDown: undefined,
};

export default Dropdown;
