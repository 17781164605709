import type { NextPage } from "next";
import Dashboard from "../components/Dashboard/Dashboard";
import { AuthGuard } from "../Guard/AuthGuard";
import Layout from "../layout/Layout";

const Home: NextPage = () => (
  <AuthGuard>
    <Layout pageName="Dashboard">
      <Dashboard />
    </Layout>
  </AuthGuard>
);

export default Home;
